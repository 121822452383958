import { FETCH_BANNER_DETAILS } from "../types";

const initialState = [];

export const bannerReducecer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_BANNER_DETAILS:
            // return [...state, ...action.payload];
            return action.payload;
        default:
            return state;
    }
};