import { FETCH_ANSWER_LIST } from "../types";

const initialState = [];

export const answerReducecer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_ANSWER_LIST:
            return action.payload;
        default:
            return state;
    }
};