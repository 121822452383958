import { FETCH_ALL_REWARDS, FETCH_TOP_REWARDS, FETCH_MY_REWARDS, FETCH_REWARDS_BANNER, UPDATE_MY_REWARDS } from "../types";

const initialState = {
    all: [],
    top: [],
    my: [],
    banner:[]
};

export const rewardsReducers = (state = initialState, action) => {
    switch(action.type){
        case FETCH_ALL_REWARDS:
            return {
                ...state,
                all: action.payload
            }
        case FETCH_TOP_REWARDS:
            return {
                ...state,
                top: action.payload
            }
        case FETCH_MY_REWARDS:
            return {
                ...state,
                my: action.payload
            }
        case UPDATE_MY_REWARDS:
            return {
                ...state,
                my: [...state.my, ...action.payload]
            }
        case FETCH_REWARDS_BANNER:
            return {
                ...state,
                banner: action.payload
            }
        default:
            return state;
    }
};