import { SET_SEARCH_KEY, SET_SEARCH_RESULT } from "../types";

const initialState = {
    searchKey: '',
    searchResult: [], 
};

export const searchReducers = (state = initialState, action) => {
    switch(action.type){
        case SET_SEARCH_KEY:
            return {
                ...state,
                searchKey: action.payload
            }
        case SET_SEARCH_RESULT:
            return {
                ...state,
                searchResult: action.payload
            }
        default:
            return state;
    }
};