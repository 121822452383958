import { combineReducers } from "redux";
import { FETCH_PRODUCTS, FETCH_LATEST_QUIZ, FETCH_QUIZ_HISTORY } from "../types";

const initialState = [];

const popularQuizReducecer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PRODUCTS:
            return action.payload;
        default:
            return state;
    }
};

const latestQuizReducecer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_LATEST_QUIZ:
            return action.payload;
        default:
            return state;
    }
};

const quizHistoryReducecer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_QUIZ_HISTORY:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

export const quizReducers = combineReducers({
    popular: popularQuizReducecer,
    latest: latestQuizReducecer,
    history: quizHistoryReducecer
});